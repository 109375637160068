import { ReactNode, RefObject } from "react";

import { Button } from "@smart/itops-ui-dom";

import {
  Page,
  Section,
  SectionButtons,
  PageWrapper,
  SectionForm,
  SectionHeading,
  SectionGap,
} from "./section-components";
import { AutoFillStatus, SectionErrors, SectionItem, TeamItem } from "../types";

export type IntakeAutoFillProps = {
  team: TeamItem;
  autoFillSection: SectionItem;
  sidebar: boolean;
  children?: ReactNode;
  onSubmit: () => void;
  onSkip: () => void;
  errors?: SectionErrors;
  errorsRef?: RefObject<HTMLDivElement>;
  submitError?: string;
  doneUploading?: boolean;
  disabled?: boolean;
  autoFillStatus?: AutoFillStatus;
};

export const IntakeAutoFill = ({
  team,
  autoFillSection,
  sidebar,
  children,
  onSubmit,
  onSkip,
  submitError,
  errors,
  errorsRef,
  doneUploading,
  disabled,
  autoFillStatus,
}: IntakeAutoFillProps) => (
  <PageWrapper sidebar={sidebar}>
    <Page>
      <Section>
        <SectionForm isForm={false}>
          <SectionButtons isForm={false} team={team} hasError={submitError}>
            <SectionHeading
              section={autoFillSection}
              errors={errors}
              errorsRef={errorsRef}
            />
            <SectionGap />
            {children}
            <SectionGap />
            <div className="buttons">
              <Button
                text="Autofill my form"
                type="button"
                name="submit"
                value="autofill"
                onClick={onSubmit}
                variant="save"
                size="base"
                disabled={
                  disabled ||
                  autoFillStatus === "filling" ||
                  autoFillStatus === "completed" ||
                  !doneUploading
                }
              />
              <Button
                text="Skip"
                variant="navigation"
                onClick={onSkip}
                kind="borderless"
                size="base"
                disabled={autoFillStatus === "filling"}
              />
            </div>
          </SectionButtons>
        </SectionForm>
      </Section>
    </Page>
  </PageWrapper>
);
